@import url(https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
.empty-chat-holder {
  width: 100%;
  height: 250px;
  margin-top: 70px;
}

.empty-chat {
  position: relative;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.empty-chat h2 {
  color: #1546dc;
  font-family: 'Abril Fatface';
  padding: 0;
  font-size: 2rem;
  margin: 25px auto 15px;
}

.empty-chat-holder img {
  width: 100%;
  height: 100%;
}

.avatar {
  width: 50px;
  height: 50px;
}

.header-icons {
  width: 120px;
  float: right;
  margin-right: 10px;
}

.header-icons span {
  line-height: 70px;
  cursor: pointer;
  font-size: 14px;
}

.header-icons .fa {
  cursor: pointer;
  margin: 10px;
}

.msg-page {
  display: flex;
  flex-direction: column;
  height: 584px;
  overflow-y: auto;
}

.input-group {
  margin-right: 20px;
  border-top: 1px solid #dee6eb;
  width: 100% !important;
  background-color: #fff;
  height: 100%;
  border-radius: 0 0 7px 7px;
}

.input-group input {
  height: 100%;
  background: #fff;
}

.input-group ::-webkit-input-placeholder {
  color: #c0c0c0 !important;
}

.input-group :-ms-input-placeholder {
  color: #c0c0c0 !important;
}

.input-group ::-ms-input-placeholder {
  color: #c0c0c0 !important;
}

.input-group ::placeholder {
  color: #c0c0c0 !important;
}

.form-control {
  border: none !important;
  border-radius: 20px !important;
}

.input-group-text {
  background: transparent !important;
  border: none !important;
}

.input-group .fa {
  color: #007bff;
  float: right;
}

.bottom-icons {
  float: left;
  margin-top: 17px;
  width: 30% !important;
  margin-left: 22px;
}

.bottom-icons .fa {
  color: #fff;
  padding: 5px;
}

.form-control:focus {
  border-color: none !important;
  box-shadow: none !important;
  border-radius: 20px;
}

.msg-footer {
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 60px;
}

#end-of-chat {
  padding-bottom: 50px;
  padding-top: 30px;
  padding-left: 20px;
}

.message-form {
  height: 100%;
}

.chat-box {
  font-family: 'Roboto';
  position: relative;
  height: 588px;
  letter-spacing: 0.5px;
  background: #f8f9fb;
  padding: 0 !important;
  box-sizing: border-box;
}

.message-input {
  padding: 6px 20px;
}

.empty-chat-sub-title {
  text-align: center;
  color: #555;
  font-size: 18px;
}

.loading-messages-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 150px;
}

.loading-text {
  color: #1546dc;
  font-size: 25px;
  margin-top: 20px;
}

.chat-bubble-row {
  display: flex;
  padding: 15px 20px;
  align-items: center;
}

.sender-name {
  font-size: 14px;
  font-weight: bold;
  padding-right: 10px;
}

.sender-time {
  font-size: 10px;
  font-weight: bold;
  padding-right: 10px;
  padding-top:8px;
}

.chat-bubble {
  border-radius: 8px;
  font-size: 16px;
  padding: 15px;
  position: relative;
}

.is-user {
  background-color: #1546DC;
}

.is-other {
  background-color: #FFF;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1)
}

.chat-box-bottom {
  margin-top: auto;
}
